.ranking-holder {
  background: #d9d9d9;
  display: flex;
  align-items: center;
  width: 96%;
  margin: 1em;
}

.button-multi-choice-position {
  margin: 2rem 0;
}

.ranking-spliter-div {
  width: 100%;
  // width: -webkit-fill-available;
  // width: -moz-available;
  // width:stretch;
  height: 100%;
  // height: -webkit-fill-available;
  // height: -moz-available;
  // height:stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ranking-holder-span {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #ff7a00;
  padding-left: 10px;
}

.ranking-holder-container {
  display: flex;
  flex-direction: column;
  flex: auto;
  gap: 1.5em;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  margin-top: 0.75rem;
}

.ranking-index {
  background: #ff7a00;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  padding: 0.7em;
  display: flex;
  align-items: center;
  // height: 100%;
  height: -webkit-fill-available;
  // height: -moz-available;
  // height:stretch;
}

.ranking-change-button-container {
  display: flex;
  flex: auto;
  gap: 10px;
  height: 100%;
}

.ranking-change-button {
  background-color: #4fb1c2;
  border: 0px;
  height: 100%;
  width: 1em;
  border-radius: 0px;
  /* background-size: 2.5em 4em; */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 5px;
  /* background-origin: border-box;*/
}

.ranking-up-button {
  background-image: url("../../../../assets/images/uparrow.svg");
  // background-size: cover;
}

.ranking-down-button {
  background-image: url("../../../../assets/images/downarrow.svg");
  // background-size: cover;
}
