.survey-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
    width:stretch;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    padding: 0 10px;
}