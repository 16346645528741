.footer-container {
  display: flex;
  align-self: center;
  width: 100%;
  // justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.footer {
  text-align: center;
  opacity: 0.35;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-setting-container {
  width: 10%;
}
.footer-setting-img {
  width: 100%;
  height: 100%;
}

.footer-text-holder {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.footer-meeting-name {
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1.25rem;
}

.footer-meeting-place {
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1.25rem;
}
