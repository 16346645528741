.container-header {
  background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  width: 100%;
  height: calc(100% - 2rem);
}

.container-introduction-header {
  display: flex;
  flex-direction: column;
}

.container-disclaimer-header {
  display: flex;
  flex-direction: column;
}

.container-header-main {
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2rem;
  // margin-bottom: 0.5rem;
}

.container-header-sub-heading {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.25rem;
}

.container-header-sub-smaller-heading {
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1.25rem;
}

.container-header-case-details {
  display: flex;
  flex-direction: column;
  text-align: initial;
  justify-content: space-evenly;
}

.container-header-case-sub-details {
  display: inherit;
  flex-direction: column;
}

.container-header-case-avatar {
  width: 25%;
  border: 1px solid #4fb1c2;
  border-radius: 10px;
  background-size: cover;
  display: flex;
}

.container-header-case {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
