.disclaimer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1em 1em 1em 1em;
  height: 100%;
  justify-content: space-evenly;
}

.disclaimer-content-checkbox {
  display: flex;
  text-align: justify;
  align-items: center;
  input {
    margin-right: 1em;
  }
}

.disclaimer-label {
  font-size: 0.65rem;
  line-height: 1rem;
}
