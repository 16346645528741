.rating-survey-holder {
  width: 95%;
  display: flex;
  flex-direction: column;
}
.rating-submit-button {
  display: flex;
  justify-content: center;
  margin-top: 4em;
}

.rating-text-area-holder {
  border-radius: 0px 0px 10px 10px;
  position: relative;
  border-bottom-color: #4fb1c2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-left-color: #4fb1c2;
  border-left-width: 1px;
  border-left-style: solid;
  border-right-color: #4fb1c2;
  border-right-width: 1px;
  border-right-style: solid;
}

.rating-text-area {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width:stretch;
  border: 0px;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.25rem;
}

.survey-feedback-header {
  background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
  border-radius: 10px 10px 0px 0px;
  font-weight: 800;
  font-size: 0.9rem;
  line-height: 1.25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1em;
}
