.header-container {
  width: 100%;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header-logo {
  height: 100%;
}
.header-bi-logo {
  height: 100%;
}
.header-logo {
  height: 2.25rem;
}

.custom-header-bi-logo{
  height: 2.5rem;
}
