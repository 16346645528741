.terms-container {
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.terms-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  // background-image: url("../../assets/images/MedQA Logo.svg");
}

.terms-heading {
  text-align: center;
  margin-bottom: 1.5rem;
  text-decoration: underline;
  font-size: 1.2rem;
  margin-right: 0.25rem;
  font-weight: 700;
}

.terms-content-heading {
  margin-bottom: 1rem;
  text-decoration: underline;
  font-size: 1.1rem;
}

.terms-content-value {
  font-size: 1rem;
  text-decoration: none;
  line-height: 1.5;
}
