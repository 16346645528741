.introduction-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  overflow-y: auto;
  height: 100%;
}

.text-bold {
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.75rem;
}

.text-semi-bold {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.75rem;
}

.intro-custom-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .intro-custom-img {
    width: 100%;
    height: 100%;
  }
}
