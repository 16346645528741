.button-multi-choice-position {
  margin: 1rem 0;
}

.multi-choice-group-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  height: 100%;
}

.multi-choice-values-group-top {
  margin-top: 1%;
  margin-left: 1%;
  margin-right: 1%;
}

.multi-choice-values-group-bottom {
  margin-left: 1%;
  margin-right: 1%;
}

.multi-choice-values-group-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width:stretch;
}

.multi-choice-group {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width:stretch;
}

.multi-choice-sub-group-heading {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 700;
  font-size: 0.85rem;
  line-height: 1.25rem;
  color: #4f4f4f;
}

.multi-choice-sub-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 85%;
  margin: 5px;
}

.choice-label-multi-group {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2rem;
  color: #ff7a00;
  width: 100%;
  display: flex;
  padding-left: 5px;
  text-align: left;
}

.mutli-choice-additional {
  background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
  border-radius: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.selection-background {
  background: #dadada;
}

.main-group {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width:stretch;
  gap: 10px;
  align-items: center;
  margin: 0.5rem;
}

.sub-group {
  display: flex;
  flex-direction: column;
  width: inherit;
  gap: 0.3em;
}

.sub-group-internal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7em;
}

.group-heading {
  background: #4f4f4f;
  padding: 5px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  width: 100%;
}

.group-heading-additional {
  padding: 5px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

.subgroup-heading {
  // background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
  padding: 5px;
  font-weight: 700;
  font-size: 0.85rem;
  line-height: 1.25rem;
  color: #4f4f4f;
  // width: 65%;
}

.checkbox-no-image {
  background-image: none;
}
