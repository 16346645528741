.app-container {
  background-image: url(assets/images/P\ screens\ merged\ BG\ 1.svg);
  height: calc(100vh - 1rem);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem 1rem;
}

.app-header {
  display: flex;
  height: 2.5rem;
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.app-popup {
  display: flex;
  height: 8rem;
  top: 48%;
  width: 75%;
  position: absolute;
}

.show-element {
  display: flex;
}

.hide-element {
  display: none;
}

.app-body {
  display: flex;
  height: calc(90% - 2.5rem);
  width: 100%;
}

.app-footer {
  display: flex;
  height: 3rem;
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
