.button-multi-choice-position {
  margin: 1rem 0;
}

.multi-choice-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  height: 100%;
}

.multi-choice-values-container {
  display: flex;
  flex-direction: column;
  // margin-top: 11%;
  // margin-left: 13%;
  // margin-right: 13%;
  gap: 0.5em;
  // margin-bottom: 11%;
  /* flex: 1 1 auto; */
  /* flex: 1 1; */
  /* flex-wrap: wrap; */
  /* justify-content: center; */
  align-items: center;
}

.multi-choice {
  display: flex;
  // gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  margin: 5px;
}

.choice-label {
  font-weight: 700;
  font-size: 0.85rem;
  line-height: 1.25rem;
  color: #ff7a00;
  padding-left: 0.2rem;
  width: 100%;
}

.mutli-choice-additional {
  background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
  border-radius: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.selection-background {
  background: #dadada;
}

.button-position {
  position: relative;
  top: min(50%, 100%);
}
