.case-details-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    overflow-y: auto;
    padding: 1em;
}

.case-details-header{
    font-weight: 700;
    font-size: 1rem;
    line-height: 2rem;
    text-transform:capitalize
}

.case-details-value{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    // text-align: justify;
}

.case-details{
    display: flex;
    flex-direction: column;
}