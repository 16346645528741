.container-content {
  background: #ffffff;
  box-shadow: 0px 4px 25px rgb(170 154 130 / 80%);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem; 
  height: calc(100% - 1rem);
  width: 100%;
}

.eventBackgroundContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  .event_background {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
}