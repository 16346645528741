@import "./assets/styles/fonts.scss";
html {
  font-family: "Poppins";
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  color: #4f4f4f;
  height: 100%;
  /* overflow-y: hidden; */
  letter-spacing: 0.35px;
}

/* @media only screen and (min-width: 768px) {
  body {
    font-size: 20px;
  }
}

@media only screen and (min-width: 320px) {
  body {
    font-size: 10px;
  }
} */

[type="checkbox"] {
  margin: 0px;
  padding: 0px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  background-color: #ffffff;
  transition: background 300ms;
  cursor: pointer;
  border: 0.5px solid #4fb1c2;
}

[type="checkbox"]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: cover;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

[type="checkbox"]:checked {
  background-color: #4fb1c2;
}

[type="checkbox"]:checked::before {
  box-shadow: none;
  background-image: url(../src/assets/images/tick\ mark.svg);
}

.checkbox-tick-mark::before {
  background-image: url(../src/assets/images/tick\ mark.svg);
}

.show-element {
  display: block;
  /* visibility: visible; */
}

.hide-element {
  display: none;
  /* visibility: hidden; */
}

button {
  width: 8rem;
  background: #1d2f56;
  border-radius: 3px;
  color: white;
  font-weight: 600;
  line-height: 1rem;
  padding: 0.5rem 1rem;
  gap: 10px;
  font-size: 0.85rem;
  font-family: "Poppins";
}

button:disabled,
button[disabled] {
  background-color: #bbbecb;
  border-radius: 3px;
}

label {
  white-space: pre-wrap;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #dadada;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #4fb1c2;
  border-radius: 2px;
}

.webkit-full {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
