.alert-section {
  top: 45%;
  background: #f9ac5a;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  height: calc(96% - 1rem);
  // width: 100%;
  display: flex;
  padding: 0.5rem;
}

.alert-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  height: 100%;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
}

.alert-text {
  font-weight: 700;
  // font-size: 0.85rem;
  // line-height: 1.25rem;
  font-size: 0.90rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
}
