.rating-icon {
  background-color: white;
  border: 0px;
  width: 1.4em;
  height: 2em;
}
.rating-icon-unselected {
  background-image: url('../../../../../assets/images/rating-unselected.svg');
}

.rating-icon-selected {
  background-image: url('../../../../../assets/images/rating-selected.svg');
}

.rating-heading {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.rating-heading-text-selected {
  background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
}

.rating-heading-text-unselected {
  background: #d9d9d9;
}

.rating-heading-text {
  font-weight: 800;
  font-size: 0.9rem;
  line-height: 1.25rem;
  padding: 1em;
}

.rating-text {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.25rem;
}

.rating-holder {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width:stretch;
  gap: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.rating-star-container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 0.5em;
}

.rating-text-container {
  display: flex;
  justify-content: space-between;
  padding-top: 0.2em;
}

.rating-submit-button {
  display: flex;
  justify-content: center;
}
