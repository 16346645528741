.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.container-header-holder {
  height: 35%;
  width: 100%;
  display: flex;
}
.container-content-holder {
  height: 62%;
  display: flex;
  width: 100%;
}
